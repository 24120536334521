<template>
	<Page
		clas="login"
		ref="page"
		hide-menu
		poster="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/4924Party_Ocean_Lawn.png"
	>
		<!-- poster="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/4906Party_Ocean_Lawn_Sparkle1_PosterImage.png"
    background-video-loop="https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/4907Party_Ocean_Lawn_Sparkle1.mp4" -->
		<v-container style="min-height: 90vh">
			<v-row style="min-height: 90vh" align="center">
				<v-col cols="12" md="6" offset-md="3">
					<div class="d-flex justify-center pb-8">
						<Logo></Logo>
					</div>

					<v-card class="glass" max-width="790px">
						<v-card-text class="px-8">
							<h4
								class="
									text-center
									white--text
									font-weight-light
								"
							>
								MARCH 28, 2022, 1:00PM EST
							</h4>
							<h2
								class="
									text-center
									white--text
									font-weight-light
								"
							>
								SPRING 2022 CONFERENCE
							</h2>

							<template v-if="error">
								<p class="error--text text-center">
									Login failed. Username & Password don't
									match.
								</p>
							</template>

							<p class="text-center white--text mb-n2 pt-8">
								LOG IN
							</p>

							<lh-form
								id="login-form"
								v-model="form.data"
								:schema="form.schema"
							></lh-form>

							<div>
								<v-checkbox
									v-model="accept_terms"
									@click.stop
									dark
								>
									<template #label>
										<div
											v-html="
												`I agree to <a style='color: #fff' href='https://livehouse.com/terms/' target='_blank'>Livehouse's Terms & Conditions</a>`
											"
										></div>
									</template>
								</v-checkbox>
							</div>
							<div class="mt-n4">
								<v-checkbox v-model="accept_nda" dark>
									<template #label>
										<div
											@click.stop
											v-html="
												`I agree to the following <a style='color: #fff' href='https://delivery.eventcdn.net/events/638/b5301970-648f-4f59-b2e3-bcb279e91f0e/5051Summit_2022_NDA_2022.03.22_d3.pdf' target='_blank'>Confidentiality and Nondisclosure Agreement</a>`
											"
										></div>
									</template>
								</v-checkbox>
							</div>
							<div class="pl-4" style="margin-top: -20px">
								<v-btn
									style="text-transform: none"
									class=""
									text
									color="white"
									:to="{ name: 'Register' }"
								>
									<span style="font-size: 0.8rem"
										>Not Registered Yet?
										<span
											style="
												text-decoration: underline;
												font-size: 0.8rem;
											"
											class="pl-2"
											>Register Here</span
										></span
									>
								</v-btn>
							</div>

							<div class="d-flex justify-end pt-8">
								<v-btn
									:disabled="!isValid"
									large
									class="primary--shadow px-16"
									@click="login({ data: form.data })"
									>LOG IN</v-btn
								>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</Page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
	name: "Login",

	data() {
		return {
			error: false,
			time: "20210914T150000Z",
			showPassword: false,
			password: "Password",

			form: {
				data: {
					username: null,
					password: null,
				},
				schema: {
					global: {
						fields: {
							solo: true,
						},
					},
					groups: [
						{
							fields: [
								{
									name: "username",
									type: "email",
									label: "Username",
									placeholder: "Username",
									required: true,
								},
							],
						},
						{
							fields: [
								{
									name: "password",
									type: "password",
									label: "Password",
									placeholder: "Password",
									required: true,
								},
							],
						},
						/*{
							fields: [
								{
									name: "terms",
									type: "checkbox",
									label: "I agree to <a style='color: #fff' href='https://livehouse.com/terms/' target='_blank'>Livehouse's Terms & Conditions</a>",
									dark: true,
									required: true,
								},
							],
						},*/
					],
				},
			},

			accept_terms: null,
			accept_nda: null,
			resetPasswordUrl: `https:/${process.env.VUE_APP_BACKEND_URL}password/reset/`,
		};
	},

	computed: {
		...mapGetters(["me", "isAuthenticated"]),
		...mapState(["event"]),
		isValid() {
			return (
				Object.values(this.form.data).every((field) => !!field) &&
				this.accept_terms &&
				this.accept_nda
			);
		},
	},

	watch: {
		"$store.getters.isAuthenticated": async function (newValue, oldValue) {
			console.log(
				"isAuthenticated change:",
				newValue,
				"oldValue:",
				oldValue
			);
			if (newValue) {
				await this.onLogin();
			}
		},
	},

	methods: {
		navigate() {
			const next = this.$route.query?.next;
			if (next) {
				this.$router.push(next);
			} else if (this.$store.getters.isAdmin) {
				this.$router.push({ name: "Home" });
			} else {
				this.$router.push({ name: "Home" });
			}
		},
		async login({ data: credentials }) {
			this.error = false;
			try {
				await this.$store.dispatch("login", credentials);
			} catch (e) {
				this.error = true;
				console.debug("Login error:", e);
			}
		},
		async onLogin() {
			// Track

			await this.$livehouse.eventcdn.beacon.track("login", {
				category: "User",
			});

			// Navigate
			this.navigate();
		},
	},
};
</script>

<style lang="scss">
#login-button {
	float: right;
}

.livehouse-app.v-application {
	#login-form {
		.v-input.v-input--checkbox {
			margin-top: -10px !important;
			padding-left: 4px;
		}
	}
}
</style>
